import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { ButtonModule } from '@teamfoster/sdk/button';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { Address } from 'src/app/addresses/models';
import { icons } from 'src/assets/dist/svg/icons';

@Component({
  selector: 'app-bottom-bar',
  standalone: true,
  imports: [CommonModule, IconModule, ButtonModule],
  templateUrl: './bottom-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomBarComponent {
  @Input() menu: MenuItem[] = [];
  @Input() address?: Address | null;
  @Input() hasDepositsService: boolean = false;

  icons = icons;

  iconFromUrl(url: string) {
    var iconName = `icon-${url.split('/').slice(-1).pop()}`;

    if (iconName == 'icon-dashboard') {
      iconName = 'icon-home';
    }

    if (!icons || !icons.length) {
      return iconName;
    }

    if (iconName == 'icon-menu') {
      iconName = 'icon-more-horizontal';
    }
    return icons?.includes(iconName) ? iconName : 'icon-plus-circle';
  }

  isHomeUrl(url: string | undefined) {
    const homeMatches = ['#', '/', ''];
    return homeMatches.includes(url || '');
  }

  get filteredMenuItems() {
    return this.menu.filter(a => !(a.url === '/inzicht' && !this.hasDepositsService));
    // return this.menu.filter(a => !(a.url === '/inzicht' && this.address?.municipalityId !== 21));
  }
}
