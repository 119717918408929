<nav class="bottom-bar d-lg-none">
  <div class="container container-size-xs">
    <ul class="bottom-bar__list d-flex">
      <li class="bottom-bar__list__item" *ngFor="let item of filteredMenuItems">
        <fstr-link
          [cssClasses]="'button button-link button--space-gray-200 bottom-bar__list__item__link'"
          [button]="{ text: item.title, url: item.url ? item.url : '', routerLink: item.routerLink }"
          [showText]="false"
          [routerLinkActiveOptions]="{ exact: isHomeUrl(item.url) }"
          [routerLinkActive]="'is--current'"
        >
          <fstr-icon [anchor]="iconFromUrl(item.url || '')"></fstr-icon>
          <span class="bottom-bar__label ms-0">{{ item.title }}</span>
        </fstr-link>
      </li>
    </ul>
  </div>
</nav>
